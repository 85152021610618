import React from 'react'
import Green from '../../assets/icons/greenIcon.svg'

const UserProject = () => {
    return (
        <div className='user-info-main-content-project'>
            <div>
                <img src={Green} alt='' className='green-icon' />
                <span>Aktif Proje:</span> ----
            </div>
            <div>
                <span>Son Çalıştığı Proje:</span> ----
            </div>
            <div>
                <span>Son Kahve Molası:</span> --.--.----- / ---- ----
            </div>
            <div>
                <span>İşe Giriş Tarihi:</span> --.--.----
            </div>
        </div>
    )
}

export default React.memo(UserProject);
