import React from 'react'
import './index.scss'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { addPoint, deletePoint, getPointsById, updatePoint } from '../../redux/point/services'
import WarningPopup from '../Global/WarningPopup';

const pointNumbers = [
    {
        id: 1,
        value: 10,
    },
    {
        id: 2,
        value: 20,
    },
    {
        id: 3,
        value: 30,
    },
    {
        id: 4,
        value: 50,
    },
    {
        id: 5,
        value: 100,
    },
]


function PointArea({ userId }) {
    const dispatch = useDispatch();
    const userPointDetails = useSelector((state) => state.point.userPointDetails);
    const [point, setPoint] = useState(0)
    const [missionName, setMissionName] = useState("")
    const [openModal, setOpenModal] = useState(false)
    const [currentMission, setCurrentMission] = useState({})
    const [isUpdate, setIsUpdate] = useState(false)

    const onChangePointNumber = (e) => {
        setPoint(+e.target.value)
    }

    const onChangeMissionName = (e) => {
        setMissionName(e.target.value)
    }

    const addPointAndMission = () => {
        dispatch(addPoint({ userId, point, description: missionName })).then(response => {
            setOpenModal(false)
            setIsUpdate(false)
            setPoint(0)
            setMissionName("")
        })
    }
    const updatePointAndMission = () => {
        dispatch(updatePoint({ missionId: currentMission.id, point, description: missionName })).then(response => {
            setIsUpdate(false)
            setPoint(0)
            setMissionName("")
        })
    }

    const showDeleteModal = (data) => {
        setOpenModal(true)
        let tempData = {
            id: data.id,
            description: data.description
        }
        setCurrentMission(tempData)
    }

    const removeMission = () => {
        let id = currentMission.id
        dispatch(deletePoint(id)).then(response => {
            setOpenModal(false)
            setIsUpdate(false)
            setPoint(0)
            setMissionName("")
        })
    }

    const editMission = (data) => {
        let tempData = {
            id: data.id,
            description: data.description
        }
        setCurrentMission(tempData)

        if (!isUpdate) {
            setPoint(data.point)
            setMissionName(data.description)
            setIsUpdate(!isUpdate)
        } if(isUpdate) {
            setPoint(0)
            setMissionName("")
            setIsUpdate(!isUpdate)
        }
    }

    useEffect(() => {
        dispatch(getPointsById(userId))
    }, [dispatch, userId])

    return (
        <>
            <WarningPopup trigger={openModal}>
                <div className='main'>
                    <div className='main-header'>
                        <h3>Warning!</h3>
                        <button onClick={() => setOpenModal(false)}><span>X</span></button>
                    </div>
                    <div className='main-content'>
                        <span> {currentMission?.description ?? ""}  <strong> <u> yazılmış olan bu görevi gerçekten silmek istiyor musunuz ?  </u></strong> </span>
                    </div>
                    <div className='main-footer'>
                        <button onClick={removeMission} className='deleteButton'>Sil</button>
                        <button className='cancelButton' onClick={() => setOpenModal(false)}>Vazgeç</button>
                    </div>
                </div></WarningPopup>
            <div className='point'>
                {userPointDetails.length > 0 && userPointDetails.map((x) => (
                    <div className='point-content-row' key={x.id}>
                        <div className='point-content-row-text'>
                            <span>+{x.point} </span>
                            <p>{x.description}</p>
                        </div>
                        <div className='point-content-row-edit'>
                            <h3 onClick={() => editMission(x)}>Düzenle</h3>
                            <span>/</span>
                            <h3 onClick={() => showDeleteModal(x)}>Kaldır</h3>
                        </div>
                    </div>
                )
                )}
                {
                    userPointDetails.length < 1 &&
                    <div className='point-content-row'>
                        <div className='point-content-row-error'>
                            <p>This person has not yet been assigned a role</p>
                        </div>
                      
                    </div>
                }

                <div className='point-content-add'>
                    <div className='point-content-add-point'>
                        <select name="point-mission" value={point} onChange={e => onChangePointNumber(e)}>
                            <option defaultValue hidden>Puan</option>
                            {
                                pointNumbers.map((x) => (
                                    <option key={x.id} value={x.value}>{x.value}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className='point-content-add-mission'>
                        <div className='point-content-add-mission-input'>
                            <input type="text" value={missionName} onChange={e => onChangeMissionName(e)} placeholder='Görev Gir' />
                        </div>
                        <div>
                            {
                                isUpdate && <span onClick={updatePointAndMission}>Güncelle</span>
                            }
                            {
                                !isUpdate && <span onClick={addPointAndMission}>Kaydet</span>
                            }


                        </div>
                    </div>
                </div>
                <div></div>
            </div>
        </>
    )
}

export default React.memo(PointArea);
