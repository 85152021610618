import React from 'react'
import './index.scss'

const DashboardTitle = ({title}) => {
    return (
        <div className='dashboard-ui-title'>
            <h2>{title}</h2>
        </div>
    )
}

export default React.memo(DashboardTitle);
