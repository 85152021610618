import React from 'react'
const PointStatistic = ({pointStatistic}) => {
  return (
    <div className='user-info-main-content-points'>
      <div className='user-info-main-content-points__d1'>
        <div className='left'>
          1 yıl <span>{pointStatistic?.oneYearTotal}</span>
        </div>
        <div className='right'>
          Yıllık Sıralama:
          <span>{pointStatistic?.oneYearSort}.</span>
        </div>
      </div>
      <div className='user-info-main-content-points__d2'>
        <div className='left'>
          6 ay <span>{pointStatistic?.sixMonthsTotal}</span>
        </div>
        <div className='right'>
          6 Aylık Sıralama:
          <span>{pointStatistic?.oneYearSort}.</span>
        </div>
      </div>
      <div className='user-info-main-content-points__d3'>
        <div className='left'>
          1 ay <span>{pointStatistic?.oneMonthTotal}</span>
        </div>
        <div className='right'>
          Aylık Sıralama:
          <span>{pointStatistic?.oneMonthSort}.</span>
        </div>
      </div>
    </div>
  )
}

export default React.memo(PointStatistic);
