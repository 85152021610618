import { useState, useEffect } from 'react'
import './index.scss'
import { useSelector, useDispatch } from 'react-redux'
import {
  addTechnicalNote, updateTechnicalNote, getCoffeeBreakNotes, updateCoffeeBreakNote, addCoffeeBreakNote, getTechnicalNotes,
  deleteCoffeeBreakNote, deleteTechnicalNote, addCoffeeBreakComment, updateCoffeeBreakComment, getCoffeeBreakCommentByNoteId, deleteCoffeeBreakComment, addTechnicalComment, updateTechnicalComment, deleteTechnicalComment, getTechnicalCommentByNoteId
} from '../../redux/notes/services'
import Edit from '../../assets/icons/editOther.svg'
import Comment from '../../assets/icons/commentIcon.svg'
import Delete from '../../assets/icons/closeIconOther.svg'
import DeleteIcon from '../../assets/icons/closeIcon.svg'
import DeleteWhite from '../../assets/icons/close_white.svg'
import UpdateWhite from '../../assets/icons/edit_white.svg'
import WarningPopup from '../Global/WarningPopup'
import PointArea from '../PointArea'
import CommentModal from '../CommentModal'
import { countCommentLength, countTechCommentLength } from '../../redux/notes/noteSlice'
import ProjectArea from '../ProjectArea'
import Image from '../common/image'

function Tabs(props) {
  const [toggleState, setToggleState] = useState(1)

  const [coffeeBreakNoteStatus, setCoffeeBreakNoteStatus] = useState(1)
  const [technicalNoteStatus, setTechnicalNoteStatus] = useState(1)

  const [technicalNote, setTechnicalNote] = useState('');
  const [coffeeBreakNote, setCoffeeBreakNote] = useState('');

  const [technicalNoteId, setTechnicalNoteId] = useState('')
  const [coffeeBreakNoteId, setCoffeeBreakNoteId] = useState('')

  const [isTechnical, setIsTechnical] = useState(true);
  const [isCoffeeBreak, setIsCoffeeBreak] = useState(true);

  const [commentCoffeeBreak, setCommentCoffeeBreak] = useState('');
  const [commentTechnical, setCommentTechnical] = useState('');

  const [commentCoffeeBreakId, setCommentCoffeeBreakId] = useState('')
  const [commentTechId, setCommentTechId] = useState('')

  const [commentIsUpdate, setCommentIsUpdate] = useState(false);
  const [commentTechIsUpdate, setCommentTechIsUpdate] = useState(false);


  const {status, error, technicalNotes, coffeeBreakNotes} = useSelector((state) => state.note);
  const dispatch = useDispatch();
  const technicalComments = useSelector((state) => state.note.commentTechnicalList);
  const coffeeBreakComments = useSelector((state) => state.note.commentCoffeeBreakList);
  
  const [openModal, setOpenModal] = useState(false)
  const [openCommentModal, setOpenCommentModal] = useState(false)
  const [openCommentTechModal, setOpenCommentTechModal] = useState(false)

  const [currentNote, setCurrentNote] = useState(null)
  const [tempComment, setTempComment] = useState("")
  const [tempTechComment, setTechTempComment] = useState("")

  const [tempCommentId, setTempCommentId] = useState(0)
  const [tempTechCommentId, setTechTempCommentId] = useState(0)

  const [commentStatus, setCommentStatus] = useState(1);
  const [commentTechStatus, setCommentTechStatus] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index)
  }

  const pic_style = {
    width: 50,
    height: 50,
    borderRadius: 50
  };

  const picture = localStorage.getItem('picture');

  useEffect(() => {
    dispatch(getTechnicalNotes(props.userId))
    dispatch(getCoffeeBreakNotes(props.userId))
  }, [dispatch, props.userId]);

  if (status === 'failed') {
    return <div className='warning'>Bu personele not ekleme yetkisine sahip değilsiniz.</div>
  }

  //CoffeeBreakNotes
  const onChangeCoffeeBreak = (event) => {
    setCoffeeBreakNote(event.target.value)
  }

  const addedCoffeeBreakNote = async () => {
    await dispatch(addCoffeeBreakNote({ id: props.userId, coffeeBreakNote, noteStatus: coffeeBreakNoteStatus }))
    await setCoffeeBreakNote('')
  }

  const updatedCoffeeBreakNote = async () => {
    await dispatch(updateCoffeeBreakNote({ id: coffeeBreakNoteId, coffeeBreakNote, noteStatus: coffeeBreakNoteStatus }))
    await setCoffeeBreakNote('')
    await setIsCoffeeBreak(true);

  }

  const changeCoffeeBreakNote = async (note) => {
    await setIsCoffeeBreak(!isCoffeeBreak)
    await setCoffeeBreakNote(note.motivation);
    await setCoffeeBreakNoteId(note.id)
  }

  const removeCoffeeBreakNote = async () => {
    dispatch(deleteCoffeeBreakNote(currentNote)).then(response => {
      setOpenModal(false)
    })
  }

  //TechnicalNotes
  const onChangeTechnical = (event) => {
    setTechnicalNote(event.target.value)
  }

  const addedTechnicalNote = async () => {
    await dispatch(addTechnicalNote({ id: props.userId, technicalNote, noteStatus: technicalNoteStatus }))
    await setTechnicalNote('')
  }

  const updatedTechnicalNote = async () => {
    await dispatch(updateTechnicalNote({ id: technicalNoteId, technicalNote, noteStatus: technicalNoteStatus }))
    await setTechnicalNote('')
    await setIsTechnical(true);
  }

  const changeTechnicalNote = async (note) => {
    await setIsTechnical(!isTechnical)
    await setTechnicalNote(note.technical);
    await setTechnicalNoteId(note.id)
  }

  const removeTechnicalNote = async () => {
    dispatch(deleteTechnicalNote(currentNote)).then(response => {
      setOpenModal(false)
    })
  }

  const sendNoteStatus = async (data) => {
    setOpenModal(true);
    if (toggleState === 1) {
      let newData = {
        noteId: data.id,
        note: data.motivation,
        noteStatus: data.noteStatus,
        userKolayIkId: data.employee.userKolayIkId,
        displayName: data.employee.displayName,
        picture: data.employee.picture,
        email: data.employee.email,
        department: data.employee.department,
        phoneNumber: data.employee.phoneNumber,
        id: data.employee.id
      }
      setCurrentNote(newData)
    }
    if (toggleState === 2) {
      let newData = {
        noteId: data.id,
        note: data.technical,
        noteStatus: data.noteStatus,
        userKolayIkId: data.employee.userKolayIkId,
        displayName: data.employee.displayName,
        picture: data.employee.picture,
        email: data.employee.email,
        department: data.employee.department,
        phoneNumber: data.employee.phoneNumber,
        id: data.employee.id
      }
      setCurrentNote(newData)
    }

  }

  //comment methods

  const onChangeCommentCoffeeBreak = (event) => {
    setCommentCoffeeBreak(event.target.value)
  }
  const onChangeCommentTech = (event) => {
    setCommentTechnical(event.target.value)
  }

  const addedComment = async () => {
    dispatch(addCoffeeBreakComment({ id: commentCoffeeBreakId, comment: commentCoffeeBreak }))
    setCommentCoffeeBreak('')
  }
  const addedTechComment = async () => {
    dispatch(addTechnicalComment({ id: commentTechId, comment: commentTechnical }))
    setCommentTechnical('')
  }

  const deletedComment = async (commentId) => {
    dispatch(deleteCoffeeBreakComment(commentId))
  }

  const deletedTechComment = async (commentId) => {
    dispatch(deleteTechnicalComment(commentId))
  }

  const openCoffeeBreakCommentModal = (note) => {
    setOpenCommentModal(true);
    setCommentCoffeeBreakId(note.id);
    setCommentStatus(note.noteStatus);
    dispatch(getCoffeeBreakCommentByNoteId(note.id));
  }
  const openTechCommentModal = (note) => {
    setOpenCommentTechModal(true);
    setCommentTechId(note.id);
    setCommentTechStatus(note.noteStatus);
    dispatch(getTechnicalCommentByNoteId(note.id));
  }

  const commentUpdate = (comment) => {
    setTempComment(comment.comment);
    setTempCommentId(comment.id);
    setCommentIsUpdate(true)
  }
  const commentTechUpdate = (comment) => {
    setTechTempComment(comment.comment);
    setTechTempCommentId(comment.id);
    setCommentTechIsUpdate(true)
  }

  const onChangeTempComment = (e) => {
    setTempComment(e.target.value);
  }
  const onChangeTechTempComment = (e) => {
    setTechTempComment(e.target.value);
  }

  const updatedByCoffeeBreakNote = () => {
    dispatch(updateCoffeeBreakNote({ id: commentCoffeeBreakId, noteStatus: commentStatus }))
  }

  const updatedByTechNote = () => {
    dispatch(updateTechnicalNote({ id: commentTechId, noteStatus: commentTechStatus }))
  }

  const updatedComment = (comment) => {
    dispatch(updateCoffeeBreakComment({ id: comment.id, comment: tempComment })).then(() => {
      setCommentIsUpdate(false)
    })
  }
  const updatedTechComment = (comment) => {
    dispatch(updateTechnicalComment({ id: comment.id, comment: tempTechComment })).then(() => {
      setCommentTechIsUpdate(false)
    })
  }


  const closeModal = () => {
    let count = coffeeBreakComments.length
    let id = commentCoffeeBreakId
    try {
      dispatch(countCommentLength({ count, id }))
    } catch (error) {
    }
    finally {
      setOpenCommentModal(false)
    }
  }

  const closeTechModal = () => {
    let count = technicalComments.length
    let id = commentTechId
    try {
      dispatch(countTechCommentLength({ count, id }))
    } catch (error) {
    }
    finally {
      setOpenCommentTechModal(false)
    }
  }


  return (
    <>
      <WarningPopup trigger={openModal}>
        <div className='main'>
          <div className='main-header'>
            <h3>Warning!</h3>
            <button onClick={() => setOpenModal(false)}><span>X</span></button>
          </div>
          <div className='main-content'>
            <span> {currentNote?.note ?? ""}  <strong> <u> yazılmış olan bu notu gerçekten silmek istiyor musunuz ?  </u></strong> </span>
          </div>
          <div className='main-footer'>
            {toggleState === 1 &&
              <button onClick={removeCoffeeBreakNote} className='deleteButton'>Sil</button>
            }
            {toggleState === 2 &&
              <button onClick={removeTechnicalNote} className='deleteButton'>Sil</button>
            }
            <button className='cancelButton' onClick={() => setOpenModal(false)}>Vazgeç</button>
          </div>
        </div>
      </WarningPopup>

      <CommentModal trigger={openCommentModal}>
        <div className='commentz'>
          <div className='commentz-header'>
            <div className='commentz-header-text'>
              {commentStatus === 1 && <span className='commentz-header-text-red'>Notun durumu:</span>}
              {commentStatus === 2 && <span className='commentz-header-text-yellow'>Notun durumu:</span>}
              {commentStatus === 3 && <span className='commentz-header-text-green'>Notun durumu:</span>}
            </div>
            <div className='commentz-header-light'>
              <div className={commentStatus === 1 ? 'commentz-header-light-first-lamb--active' : 'commentz-header-light-first-lamb'} onClick={() => setCommentStatus(1)}></div>
              <div className={commentStatus === 2 ? 'commentz-header-light-second-lamb--active' : 'commentz-header-light-second-lamb'} onClick={() => setCommentStatus(2)}></div>
              <div className={commentStatus === 3 ? 'commentz-header-light-third-lamb--active' : 'commentz-header-light-third-lamb'} onClick={() => setCommentStatus(3)}></div>
            </div>
            <div className='commentz-header-update' onClick={updatedByCoffeeBreakNote}>
              Güncelle
            </div>
            <div className='commentz-header-close'>
              <button onClick={() => closeModal()}>
                <img src={DeleteIcon} alt='Close' />
              </button>
            </div>

          </div>
          <div className='commentz-content'>
            <div className='commentz-content-newComment'>
              <div className='commentz-content-newComment-img'>
                <img src={picture} style={pic_style} alt='' referrerPolicy='no-referrer' />
              </div>
              <div className='commentz-content-newComment-input'>
                <input type='text' placeholder='Yeni Yorum Ekle' autoFocus value={commentCoffeeBreak} onChange={onChangeCommentCoffeeBreak} />
              </div>
              <div className='commentz-content-newComment-button'>
                <button onClick={addedComment}>Kaydet</button>
              </div>
              {/* {!isCoffeeBreak && <button onClick={() => updatedCoffeeBreakNote()}>Güncelle</button>} */}
            </div>
            {coffeeBreakComments.length > 0 ? coffeeBreakComments.map((comment) => (
              <div key={comment.id} className='commentz-content-box'>
                <div className='commentz-content-box-left'>
                  <img src={comment.user.picture} alt='user' />
                </div>
                <div className={commentIsUpdate && comment.id === tempCommentId ? 'commentz-content-box-middle-isUpdate' : 'commentz-content-box-middle'}>
                  <div className='commentz-content-box-middle-name'>
                    <span>
                      {/* {note.user.displayName.split(" ")[0][0].replace(/İ/gim, "i").toUpperCase() + note.user.displayName.split(" ")[0].substring(1).toLowerCase()} {note.user.displayName.split(" ")[1][0].replace(/İ/gim, "i").toUpperCase() + note.user.displayName.split(" ")[1].substring(1).toLowerCase()} */}
                      {comment.user?.displayName?.split(" ")[0][0].replace(/İ/gim, "i").toUpperCase() + comment.user?.displayName?.split(" ")[0].substring(1).toLowerCase()} {comment.user?.displayName?.split(" ")[1][0].replace(/İ/gim, "i").toUpperCase() + comment.user?.displayName?.split(" ")[1].substring(1).toLowerCase()} {comment.user?.displayName?.split(" ")[2] && comment.user?.displayName?.split(" ")[2][0].replace(/İ/gim, "i").toUpperCase() + comment.user?.displayName?.split(" ")[2].substring(1).toLowerCase()}
                    </span>
                  </div>
                  <div className='commentz-content-box-middle-date'>
                    <span>{new Intl.DateTimeFormat("tr-TR", { dateStyle: 'full', timeStyle: 'short' }).format(new Date(comment.modifiedDate))}</span>
                  </div>
                  <div className='commentz-content-box-middle-description'>
                    {/* {!commentIsUpdate && } */}
                    {commentIsUpdate && comment.id === tempCommentId ? <input type="text" autoFocus value={tempComment} onChange={(e) => onChangeTempComment(e)} /> : <span>Yorum : {comment.comment}</span>}
                  </div>
                  {commentIsUpdate && comment.id === tempCommentId &&
                    <div className='commentz-content-box-middle-buttons'>
                      <div onClick={() => updatedComment(comment)} className='commentz-content-box-middle-buttons-submit'>
                        <button>Kaydet</button>
                      </div>
                      <div className='commentz-content-box-middle-buttons-cancel'>
                        <button onClick={() => setCommentIsUpdate(false)}>İptal et</button>
                      </div>
                    </div>
                  }

                </div>
                {!commentIsUpdate &&
                  <div className='commentz-content-box-right'>
                    <div onClick={() => commentUpdate(comment)}>
                      <img className='commentz-update' src={UpdateWhite} alt='Edit' />
                    </div>
                    <div onClick={() => deletedComment(comment.id)}>
                      <img className='commentz-delete' src={DeleteWhite} alt='Delete' />
                    </div>
                  </div>
                }

              </div>
            )) : <div className='commentz-content-noData'>
              <h3><i>Henüz bu nota bir yorum yapılmamış...</i></h3>
            </div>
            }
          </div>
          {/* <div className='main-footer'>
            {toggleState === 1 &&
              <button  className='deleteButton'>Sil</button>
            }
            {toggleState === 2 &&
              <button  className='deleteButton'>Sil</button>
            }
            <button className='cancelButton' onClick={() => setOpenModal(false)}>Vazgeç</button>
          </div> */}
        </div>
      </CommentModal>

      <CommentModal trigger={openCommentTechModal}>
        <div className='commentz'>
          <div className='commentz-header'>
            <div className='commentz-header-text'>
              {commentTechStatus === 1 && <span className='commentz-header-text-red'>Notun durumu:</span>}
              {commentTechStatus === 2 && <span className='commentz-header-text-yellow'>Notun durumu:</span>}
              {commentTechStatus === 3 && <span className='commentz-header-text-green'>Notun durumu:</span>}
            </div>
            <div className='commentz-header-light'>
              <div className={commentTechStatus === 1 ? 'commentz-header-light-first-lamb--active' : 'commentz-header-light-first-lamb'} onClick={() => setCommentTechStatus(1)}></div>
              <div className={commentTechStatus === 2 ? 'commentz-header-light-second-lamb--active' : 'commentz-header-light-second-lamb'} onClick={() => setCommentTechStatus(2)}></div>
              <div className={commentTechStatus === 3 ? 'commentz-header-light-third-lamb--active' : 'commentz-header-light-third-lamb'} onClick={() => setCommentTechStatus(3)}></div>
            </div>
            <div className='commentz-header-update' onClick={updatedByTechNote}>
              Güncelle
            </div>
            <div className='commentz-header-close'>
              <button onClick={() => closeTechModal()}>
                <img src={DeleteIcon} alt='Close' />
              </button>
            </div>

          </div>
          <div className='commentz-content'>
            <div className='commentz-content-newComment'>
              <div className='commentz-content-newComment-img'>
                <img src={picture} style={pic_style} alt='' referrerPolicy='no-referrer' />
              </div>
              <div className='commentz-content-newComment-input'>
                <input type='text' placeholder='Yeni Yorum Ekle' autoFocus value={commentTechnical} onChange={onChangeCommentTech} />
              </div>
              <div className='commentz-content-newComment-button'>
                <button onClick={addedTechComment}>Kaydet</button>
              </div>
              {/* {!isCoffeeBreak && <button onClick={() => updatedCoffeeBreakNote()}>Güncelle</button>} */}
            </div>
            {technicalComments.length > 0 ? technicalComments.map((comment) => (
              <div key={comment.id} className='commentz-content-box'>
                <div className='commentz-content-box-left'>
                  <img src={comment.user.picture} alt='user' />
                </div>
                <div className={commentTechIsUpdate && comment.id === tempTechCommentId ? 'commentz-content-box-middle-isUpdate' : 'commentz-content-box-middle'}>
                  <div className='commentz-content-box-middle-name'>
                    <span>
                      {/* {note.user.displayName.split(" ")[0][0].replace(/İ/gim, "i").toUpperCase() + note.user.displayName.split(" ")[0].substring(1).toLowerCase()} {note.user.displayName.split(" ")[1][0].replace(/İ/gim, "i").toUpperCase() + note.user.displayName.split(" ")[1].substring(1).toLowerCase()} */}
                      {comment.user?.displayName?.split(" ")[0][0].replace(/İ/gim, "i").toUpperCase() + comment.user?.displayName?.split(" ")[0].substring(1).toLowerCase()} {comment.user?.displayName?.split(" ")[1][0].replace(/İ/gim, "i").toUpperCase() + comment.user?.displayName?.split(" ")[1].substring(1).toLowerCase()} {comment.user?.displayName?.split(" ")[2] && comment.user?.displayName?.split(" ")[2][0].replace(/İ/gim, "i").toUpperCase() + comment.user?.displayName?.split(" ")[2].substring(1).toLowerCase()}
                    </span>
                  </div>
                  <div className='commentz-content-box-middle-date'>
                    <span>{new Intl.DateTimeFormat("tr-TR", { dateStyle: 'full', timeStyle: 'short' }).format(new Date(comment.modifiedDate))}</span>
                  </div>
                  <div className='commentz-content-box-middle-description'>
                    {/* {!commentIsUpdate && } */}
                    {commentTechIsUpdate && comment.id === tempTechCommentId ? <input type="text" autoFocus value={tempTechComment} onChange={(e) => onChangeTechTempComment(e)} /> : <span>Yorum : {comment.comment}</span>}
                  </div>
                  {commentTechIsUpdate && comment.id === tempTechCommentId &&
                    <div className='commentz-content-box-middle-buttons'>
                      <div onClick={() => updatedTechComment(comment)} className='commentz-content-box-middle-buttons-submit'>
                        <button>Kaydet</button>
                      </div>
                      <div className='commentz-content-box-middle-buttons-cancel'>
                        <button onClick={() => setCommentTechIsUpdate(false)}>İptal et</button>
                      </div>
                    </div>
                  }

                </div>
                {!commentTechIsUpdate &&
                  <div className='commentz-content-box-right'>
                    <div onClick={() => commentTechUpdate(comment)}>
                      <img className='commentz-update' src={UpdateWhite} alt='Edit' />
                    </div>
                    <div onClick={() => deletedTechComment(comment.id)}>
                      <img className='commentz-delete' src={DeleteWhite} alt='Delete' />
                    </div>
                  </div>
                }

              </div>
            )) : <div className='commentz-content-noData'>
              <h3><i>Henüz bu nota bir yorum yapılmamış...</i></h3>
            </div>
            }
          </div>
        </div>
      </CommentModal>

      <div className='tabs-wrapper'>
        <button
          className={toggleState === 1 ? 'tabs active-tabs' : 'tabs'}
          onClick={() => toggleTab(1)}
        >
          CoffeeBreak
        </button>
        <button
          className={toggleState === 2 ? 'tabs active-tabs' : 'tabs'}
          onClick={() => toggleTab(2)}
        >
          TechTalk
        </button>

        <button
          className={toggleState === 3 ? 'tabs active-tabs' : 'tabs'}
          onClick={() => toggleTab(3)}
        >
          Görevler
        </button>
        <button
          className={toggleState === 4 ? 'tabs active-tabs' : 'tabs'}
          onClick={() => toggleTab(4)}
        >
          Projeler
        </button>
      </div>

      <div className='content-tabs'>
        <div className={toggleState === 1 ? 'content  active-content' : 'content'}>
          <div className='notes'>
            <img src={picture} style={pic_style} alt='' referrerPolicy='no-referrer' />
            <input type='text' disabled={status === 'loading'} placeholder={!openCommentModal ? 'Coffee Break Note' : ''} value={coffeeBreakNote} autoFocus onChange={onChangeCoffeeBreak} />
            <div className={coffeeBreakNoteStatus === 1 ? 'first-lamb--active' : 'first-lamb'} onClick={() => setCoffeeBreakNoteStatus(1)} />
            <div className={coffeeBreakNoteStatus === 2 ? 'second-lamb--active' : 'second-lamb'} onClick={() => setCoffeeBreakNoteStatus(2)} />
            <div className={coffeeBreakNoteStatus === 3 ? 'third-lamb--active' : 'third-lamb'} onClick={() => setCoffeeBreakNoteStatus(3)} />
            {!isCoffeeBreak && <button onClick={() => updatedCoffeeBreakNote()}>Güncelle</button>}
            {isCoffeeBreak && <button onClick={() => addedCoffeeBreakNote()}>Kaydet</button>}
          </div>
          {status !== 'loading' &&
            coffeeBreakNotes.map((note) => (
              <div className={note.noteStatus === 1 ? 'comment comment-red' : note.noteStatus === 2 ? 'comment comment-yellow' : 'comment comment-green'} key={note.id}>
                <img src={note.user.picture} alt='' referrerPolicy='no-referrer' />
                {/* <Image displayName={note?.user?.displayName} picture={note?.user?.picture} /> */}
                <div className='comment-line'>
                  {/* {note.user.displayName.split(" ")[0][0].replace(/İ/gim, "i").toUpperCase() + note.user.displayName.split(" ")[0].substring(1).toLowerCase()} {note.user.displayName.split(" ")[1][0].replace(/İ/gim, "i").toUpperCase() + note.user.displayName.split(" ")[1].substring(1).toLowerCase()} */}

                  <div className='data-area'>
                    <div className='data-area-left' onClick={() => openCoffeeBreakCommentModal(note)}>
                      <div className='data-area-left-name'><b>{note.user.displayName.split(" ")[0][0].replace(/İ/gim, "i").toUpperCase() + note.user.displayName.split(" ")[0].substring(1).toLowerCase()} {note.user.displayName.split(" ")[1][0].replace(/İ/gim, "i").toUpperCase() + note.user.displayName.split(" ")[1].substring(1).toLowerCase()}</b>&nbsp;</div>
                      <div className='data-area-left-text'> {note.motivation}</div>
                    </div>
                    <div className='data-area-right'>
                      <div className='data-area-right-edit' onClick={() => changeCoffeeBreakNote(note)}>
                        {note.noteStatus === 1 && <img width={18} height={18} src={Edit} alt='Edit' />}
                        {note.noteStatus === 2 && <img width={18} height={18} src={Edit} alt='Edit' />}
                        {note.noteStatus === 3 && <img width={18} height={18} src={Edit} alt='Edit' />}
                      </div>
                      <div className='data-area-right-remove' onClick={() => sendNoteStatus(note)}>
                        {<img width={14} height={14} src={Delete} alt='Edit' referrerPolicy='no-referrer' />}
                      </div>
                    </div>
                  </div>
                  <div className='date' onClick={() => openCoffeeBreakCommentModal(note)}>
                    <div className='date-text'>Son Güncellenme Tarihi:</div>
                    <div className='date-datetime'>{new Intl.DateTimeFormat("tr-TR", { dateStyle: 'full', timeStyle: 'short' }).format(new Date(note.modifiedDate))}</div>
                    <div className='date-noteCount'> <img width={18} height={18} src={Comment} alt='Edit' referrerPolicy='no-referrer' />&nbsp; {note.commentCount} Yorum
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className={toggleState === 2 ? 'content  active-content' : 'content'}>

          <div className='notes'>
            <img src={picture} style={pic_style} alt='' referrerPolicy='no-referrer' />
            <input type='text' disabled={status === 'loading'} placeholder={!openCommentTechModal ? 'Technical Note' : ''} value={technicalNote} autoFocus onChange={onChangeTechnical} />
            <div className={technicalNoteStatus === 1 ? 'first-lamb--active' : 'first-lamb'} onClick={() => setTechnicalNoteStatus(1)} />
            <div className={technicalNoteStatus === 2 ? 'second-lamb--active' : 'second-lamb'} onClick={() => setTechnicalNoteStatus(2)} />
            <div className={technicalNoteStatus === 3 ? 'third-lamb--active' : 'third-lamb'} onClick={() => setTechnicalNoteStatus(3)} />
            {!isTechnical && <button onClick={() => updatedTechnicalNote()}>Güncelle</button>}
            {isTechnical && <button onClick={() => addedTechnicalNote()}>Kaydet</button>}
          </div>
          {status !== 'loading' &&
            technicalNotes.map((note) => (
              <div className={note.noteStatus === 1 ? 'comment comment-red' : note.noteStatus === 2 ? 'comment comment-yellow' : 'comment comment-green'} key={note.id}>
                <img src={note.user.picture} alt='' referrerPolicy='no-referrer' />
                <div className='comment-line'>
                  {/* {note.user.displayName.split(" ")[0][0].replace(/İ/gim, "i").toUpperCase() + note.user.displayName.split(" ")[0].substring(1).toLowerCase()} {note.user.displayName.split(" ")[1][0].replace(/İ/gim, "i").toUpperCase() + note.user.displayName.split(" ")[1].substring(1).toLowerCase()} */}

                  <div className='data-area'>
                    <div className='data-area-left' onClick={() => openTechCommentModal(note)}>
                      <div className='data-area-left-name'><b>{note.user.displayName.split(" ")[0][0].replace(/İ/gim, "i").toUpperCase() + note.user.displayName.split(" ")[0].substring(1).toLowerCase()} {note.user.displayName.split(" ")[1][0].replace(/İ/gim, "i").toUpperCase() + note.user.displayName.split(" ")[1].substring(1).toLowerCase()}</b>&nbsp;</div>
                      <div className='data-area-left-text'> {note.technical}</div>

                    </div>
                    <div className='data-area-right'>

                      <div className='data-area-right-edit' onClick={() => changeTechnicalNote(note)}>
                        {note.noteStatus === 1 && <img width={18} height={18} src={Edit} alt='Edit' />}
                        {note.noteStatus === 2 && <img width={18} height={18} src={Edit} alt='Edit' />}
                        {note.noteStatus === 3 && <img width={18} height={18} src={Edit} alt='Edit' />}
                      </div>
                      <div className='data-area-right-remove' onClick={() => sendNoteStatus(note)}>
                        {<img width={14} height={14} src={Delete} alt='Edit' referrerPolicy='no-referrer' />}
                      </div>
                    </div>
                  </div>
                  <div className='date' onClick={() => openTechCommentModal(note)}>
                    <div className='date-text'>Son Güncellenme Tarihi:</div>
                    <div className='date-datetime'>{new Intl.DateTimeFormat("tr-TR", { dateStyle: 'full', timeStyle: 'short' }).format(new Date(note.modifiedDate))}</div>
                    <div className='date-noteCount'> <img width={18} height={18} src={Comment} alt='Edit' referrerPolicy='no-referrer' />&nbsp; {note.commentCount} Yorum
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className={toggleState === 3 ? 'content  active-content' : 'content'}>
          <PointArea userId={props.userId} />
        </div>

        <div className={toggleState === 4 ? 'content  active-content' : 'content'}>
          <ProjectArea userId={props.userId} />
        </div>

      </div>
    </>
  )
}

export default Tabs;