import React from 'react'
import './index.scss'
import profileMeeting from '../../../assets/icons/profileMeeting.svg'
import DashboardTitle from '../DashboardTitle'
import SeeAll from '../../SeeAll'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getUserListByPoint } from '../../../redux/point/services'
import { useState } from 'react'
import Image from '../../common/image'
import { useNavigate } from 'react-router-dom'

function PointList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pointList = useSelector((state) => state.point.pointList);
  const [arrayNumber, setArrayNumber] = useState(3)
  useEffect(() => {
    dispatch(getUserListByPoint())
  }, [dispatch]
  )

  const goDetail = (id) => {
    navigate(`/personal/${id}`)
  }
  return (
    <>
      <DashboardTitle title="Puan Listesi" />
      {
        pointList.slice(0, arrayNumber).map(x => <section className='point-list'>
          <Image picture={x.picture} displayName={x.displayName} />
          <div className='point-list-content' onClick={() => goDetail(x.id)}>
            <h3>{x.displayName}</h3>
            <p>{x.totalPoint}</p>
          </div>
        </section>
        )
      }
      <SeeAll arrayNumber={arrayNumber} setArrayNumber={setArrayNumber} />
    </>
  )
}

export default React.memo(PointList);
