import {createSlice} from '@reduxjs/toolkit';
import {getPointsById,addPoint,updatePoint,deletePoint,getUserListByPoint, getPointStatistics} from './services'

export const pointSlice = createSlice({
    name: 'point',
    initialState: {
        status:'idle',
        error:'',
        userPointDetails:[],
        userStatistic:null,
        pointList:[]
    },
    reducers:{
    },
    extraReducers:{
        [getPointsById.pending]: (state, action) => {
            state.status = "loading";
        },
        [getPointsById.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.userPointDetails = [...action.payload]
        },
        [getPointsById.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },


        [addPoint.pending]: (state, action) => {
            state.status = "loading";
        },
        [addPoint.fulfilled]: (state, action) => {
            state.userPointDetails.push(action.payload)
            state.status = 'succeeded';
        },
        [addPoint.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },


        [updatePoint.pending]: (state, action) => {
            state.status = "loading";
        },
        [updatePoint.fulfilled]: (state, action) => {
            let data = action.payload
            const index = state.userPointDetails.findIndex(x => x.id === data.id)
            state.userPointDetails[index].point = data.point
            state.userPointDetails[index].description = data.description
            state.status = 'succeeded';
        },
        [updatePoint.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },


        [deletePoint.pending]: (state, action) => {
            state.status = "loading";
        },
        [deletePoint.fulfilled]: (state, action) => {
            let id = action.payload
            const filtered = state.userPointDetails.filter(x=>x.id !== id)
            state.userPointDetails = [...filtered]
            state.status = 'succeeded';
        },
        [deletePoint.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },


        [getUserListByPoint.pending]: (state, action) => {
            state.status = "loading";
        },
        [getUserListByPoint.fulfilled]: (state, action) => {
            state.pointList = [...action.payload]
            state.status = 'succeeded';
        },
        [getUserListByPoint.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

        [getPointStatistics.pending]: (state, action) => {
            state.status = "loading";
        },
        [getPointStatistics.fulfilled]: (state, action) => {
            state.userStatistic = {...action.payload}
            state.status = 'succeeded';
        },
        [getPointStatistics.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

    }

});

export default pointSlice.reducer;