import React from 'react'
import './index.scss'
import Layout from '../../components/Layout/Layout'
import PieChart from '../../components/PieChart'
import threePointsIcon from '../../assets/icons/threePointsIcon.svg'
import CriticalMeeting from '../../components/dashboard/CriticalMeeting'
import SeeAll from '../../components/SeeAll'
// import LeaderProfile from '../../assets/icons/leaderProfile.svg'
// import Star from '../../assets/icons/star.svg'
// import PointList from '../../components/PointList'
import NonMeeting from '../../components/NonMeeting'
import UrgentNote from '../../components/UrgentNote'
// import UpcomingMeetings from '../../components/UpcomingMeetings'
import WarningPopup from '../../components/Global/WarningPopup'
import DashboardTitle from '../../components/dashboard/DashboardTitle'
import PointList from '../../components/dashboard/PointList'

function Home() {
  return (
    <div className='home-w'>
      <Layout>
        <div className='home-wrapper'>
          <section className='home-w-main-left'>
            <div className='home-w-main-left__meeting'>
              <div className='header'>
              <DashboardTitle title="Not Durumu" />
              </div>
              <PieChart />
            </div>
            <div className='home-w-main-left__critical'>
              <CriticalMeeting />
              {/* <SeeAll /> */}
            </div>
          </section>
          <section className='home-w-main-right'>
            <div className='home-w-main-right__LP'>
              <div className='point'>
                <UrgentNote />
                <WarningPopup />
                {/* <NonMeeting /> */}
                {/* <SeeAll /> */}
              </div>

              {/* Point List */}
              <div className='home-w-main-left__critical'>
                <PointList />
               
              </div>
              {/* <SeeAll /> */}
              
            </div>
            <div className='upcoming'>
              <NonMeeting />
            </div>
          </section>
        </div>
      </Layout>
    </div>
  )
}

export default Home
