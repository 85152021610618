import React from 'react'
import Back from '../../assets/icons/back_black.svg'
import './index.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPointStatistics } from '../../redux/point/services'
import PointStatistic from '../PointStatistic'
import UserContact from '../UserContact'
import UserProject from '../UserProject'

function PersonDetail(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { userStatistic, userPointDetails } = useSelector((state) => state.point);
    const params = useParams();

    useEffect(() => {
        if (params.personalId) {
            dispatch(getPointStatistics(params.personalId))
        }
    }, [dispatch, params.personalId, userPointDetails]
    )

    const goBack = () => {
        navigate(`/personal`)
    }

    return (
        <>
            <div className='user-info-title'>
                <div className='user-info-title-back' onClick={goBack}>
                    <img src={Back} alt='Back' className='user-info-title-img' />
                    <span>Geri</span>
                </div>
                <div className='user-info-title-text'><h2>{props.user.firstName} {props.user.lastName}</h2></div>
                <div></div>

            </div>
            <div className='user-info'>

                <div className='user-info-main'>
                    <div className='user-info-main-content'>
                        <div>
                            {props.user.picture === null ?
                                <div className='user-info-main-content-notImage'>
                                    <span>{props.user.firstName.charAt(0).toUpperCase()}</span>
                                </div>
                                :
                                <div>
                                    <img className='user-info-main-content-hasImage' src={props.user.picture} alt='' />
                                </div>

                            }
                        </div>
                        <UserProject />
                        <PointStatistic pointStatistic={userStatistic} />
                    </div>
                    <UserContact user={props.user} />
                </div>
            </div>

            {/* <div className='modal-content'>
                <h1>{"props.user.displayName"}</h1>
                <br></br>
                <h1>{"props.user?.position"}</h1>
                <button className='close-button'>
                    <img src={Close} alt='' />
                </button>
                <div className='user-info-main-content'> */}
            {/* {
                        props.user.picture === null ?
                            <div className='user-info-main-content-image'>
                                <div className='user-info-main-content-image-text'>{"props.user.displayName.charAt(0).toUpperCase()"}
                                </div>
                            </div>
                            :
                            <img className='user-info-main-content-image' src={"props.user.picture"} alt='' />
                    } */}
            {/*<img src={Star} alt='' className='star' />*/}

        </>
    )
}

export default PersonDetail;
