import {createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios'
import {API_BASE_URL} from '../../constants'
import {ACCESS_TOKEN} from '../../constants'


//point CRUD

export const getPointsById = createAsyncThunk('point/getPointsById',async (userId) => {
    const res = await axios.get(`${API_BASE_URL}/point/getPointsById/${userId}`,
    { headers: {"Authorization" : `Bearer ${ACCESS_TOKEN}`}});
    return res.data;
});

export const addPoint = createAsyncThunk('point/addPoint',async (data) => {
    const res = await axios.post(`${API_BASE_URL}/point/${data.userId}`,{point:data.point,description:data.description},
    { headers: {"Content-Type":"application/json","Authorization" : `Bearer ${ACCESS_TOKEN}`}});
    return res.data;
});

export const updatePoint = createAsyncThunk('point/updatePoint',async (data) => {
    const res = await axios.put(`${API_BASE_URL}/point/${data.missionId}`,{point:data.point,description:data.description},
    { headers: {"Content-Type":"application/json","Authorization" : `Bearer ${ACCESS_TOKEN}`}});
    return res.data;
});

export const deletePoint = createAsyncThunk('point/deletePoint',async (id) => {
    const res = await axios.delete(`${API_BASE_URL}/point/${id}`,
    { headers: {"Authorization" : `Bearer ${ACCESS_TOKEN}`}});
    return id;
});


// order by point
export const getUserListByPoint = createAsyncThunk('point/getUserListByPoint',async () => {
    const res = await axios.get(`${API_BASE_URL}/employees?page=0&sort=point,desc&&size=10 `,
    { headers: {"Authorization" : `Bearer ${ACCESS_TOKEN}`}});
    return res.data.content;
});

//point statistic
export const getPointStatistics = createAsyncThunk('point/getPointStatistics',async (id) => {
    const res = await axios.get(`${API_BASE_URL}/point/getPointStatistics?id=${id}`,
    { headers: {"Authorization" : `Bearer ${ACCESS_TOKEN}`}});
    return res.data;
});

