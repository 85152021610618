export const API_BASE_URL = 'https://api.coffeebreak.appcent.dev';
//export const API_BASE_URL = 'http://localhost:8080';

export const ACCESS_TOKEN = localStorage.token;
export const OAUTH2_REDIRECT_URI = 'https://coffeebreak.appcent.dev/oauth2/redirect'
export const GOOGLE_AUTH_URL = API_BASE_URL + '/oauth2/authorization/google';

export const BASE_URL = API_BASE_URL; //Cumali

/*
export const API_BASE_URL = 'https://api.coffeebreak.appcent.mobi';
//export const API_BASE_URL = 'http://localhost:8080';

export const ACCESS_TOKEN = localStorage.token;
export const OAUTH2_REDIRECT_URI = 'https://coffeebreak.appcent.dev/oauth2/redirect'
export const GOOGLE_AUTH_URL = API_BASE_URL + '/oauth2/authorization/google';

export const BASE_URL = API_BASE_URL; //Cumali
*/