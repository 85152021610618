import React from 'react'
import './index.scss'

const Image = ({ picture, displayName }) => {
    return (
        <div className='global-image'>
            {
                picture === null ?
                    <div className='global-image-null'>
                        <span>{displayName.charAt(0).toUpperCase()}</span></div>
                    :
                    <img src={picture} alt='user' />
            }
        </div>
    )
}

export default React.memo(Image);
