import React from 'react'
import Mail from '../../assets/icons/mailIcon.svg'
import Phone from '../../assets/icons/phoneIcon.svg'
import Location from '../../assets/icons/locationIcon.svg'
const UserContact = ({ user }) => {
    return (
        <div className='user-info-main-content-footer'>
            <div className='title'>
                <section>
                    <img src={Mail} alt='' /> E - MAIL
                    <div>{user.email}</div>
                </section>
                <section>
                    <img src={Phone} alt='' /> TELEFON
                    <div>{user.phoneNumber}</div>
                </section>
                <section>
                    <img src={Location} alt='' /> DEPARTMAN
                    <div>{user.department}</div>
                </section>
            </div>
        </div>
    )
}

export default React.memo(UserContact);
