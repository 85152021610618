import React from 'react'
import './index.scss'
// import Modal from '../Modal'
import { useNavigate } from 'react-router-dom';
import Image from '../common/image';

function FullNameLine({user}) {
  // const [modal, setModal] = useState(false)
  // const toggleModal = () => setModal(!modal)
  const navigate = useNavigate();

  const goDetail = (id) => {
    navigate(`/personal/${id}`)
  }

  return (
    <>
      <section className='fullName-line '>
        
        <Image picture={user?.picture} displayName={user?.displayName} />
        
        <div onClick={()=>goDetail(user.id)} className='fullName-line-content'>
          <h3>{user.displayName}</h3>
        </div>
      </section>
      {/* {modal && <Modal user={user} state={(modal, setModal)} />} */}
    </>
  )
}

export default React.memo(FullNameLine);
