import React from 'react'
import './index.scss'

function SeeAll({ arrayNumber, setArrayNumber }) {
  return (
    <div className='see-all'>
      {arrayNumber === 3 && <button onClick={() => setArrayNumber(10)}>İlk 10'u Göster</button>}
      {arrayNumber === 10 && <button onClick={() => setArrayNumber(3)}>İlk 3'ü Göster</button>}
    </div>
  )
}

export default React.memo(SeeAll);
