import React from 'react'
import { useNavigate } from 'react-router-dom';
import Image from '../common/image';
import './index.scss'

function UrgentNoteList({ user }) {

  // const [modal, setModal] = useState(false)
  // const toggleModal = () => setModal(!modal)
  const navigate = useNavigate();

  const goDetail = (id) => {
    navigate(`/personal/${id}`)
  }

  return (
    <>
      <section className='urgent-note-list'>
        <Image displayName={user?.displayName} picture={user?.picture} />

        <div className='urgent-note-list-content' onClick={() => goDetail(user.id)}>
          {/* <div onClick={() => goDetail(user.id)} className='text'> */}
          <h3>{user.displayName}</h3>
          {/* </div> */}

          <div className='square'>
            <div className='square-red'>
              <p>{user.noteCounts["1"]}</p>
            </div>
            <div className='square-yellow'>
              <p>{user.noteCounts["2"]}</p>
            </div>
          </div>
        </div>

      </section>
      {/* {modal && <Modal user={user} state={(modal, setModal)} />} */}
    </>
  )
}

export default React.memo(UrgentNoteList);
