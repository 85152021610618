import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProjects } from '../../redux/users/services';

const ProjectArea = ({ userId }) => {
    const dispatch = useDispatch();
    const userProjects = useSelector((state) => state.user.userProjects);
    useEffect(() => {
        dispatch(getUserProjects(userId))
    }, [dispatch, userId]);
    return (
        <div>
            <h3>Çalıştığı Projeler</h3>
            {
                userProjects.length > 0 ?
                    userProjects.map((project) => (
                        <li key={project.id} className="project-name">{project.name}</li>
                    )) : <p className='project-name'>Does not have a project</p>
            }
        </div>
    )
}

export default React.memo(ProjectArea);
