import React from 'react'
import './index.scss'
import { useEffect } from 'react'
import './index.scss'
import { useSelector, useDispatch } from 'react-redux'
import { noteStatusUsers } from '../../redux/notes/services'
import UrgentNoteList from '../UrgentNoteList'
import DashboardTitle from '../dashboard/DashboardTitle'


function NonMeeting() {
  const dispatch = useDispatch();
  const users = useSelector(state => state.note.urgentNoteUsers);

  useEffect(() => {
    dispatch(noteStatusUsers())
  }, [dispatch]
  )


  return (
    <>
      <section>
        <div>
          <DashboardTitle title="Acil Notlu Çalışanlar" />
          {
            users.length > 0 ?
              users.map((user) => (
                <div key={user.id}>
                  <UrgentNoteList user={user} />
                </div>
              )
              )
              :
              <div className='urgentNot'>Böyle bir çalışan yok</div>
          }

        </div>
      </section>
    </>
  )
}

export default React.memo(NonMeeting);
